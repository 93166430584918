import React from 'react'
import { Menu as AntdMenu, Dropdown } from 'antd'
import { Link, withRouter } from 'react-router-dom'
import { MenuWrapper, MenuButton } from './styles'
import { AkaTypography } from 'components'
import { MenuOutlined, SettingOutlined } from '@ant-design/icons'
import { ROUTES } from 'modules/navigation'
import { useUser } from 'providers'
import userIcon from 'img/profile-user.svg'

const styles = {
	user: { margin: '0 0 0 16px' },
	icon: { marginRight: 8, marginBottom: 4 },
	menu: { fontSize: 24 },
	settings: { fontSize: 16, margin: '0 16px', color: 'white' }
}

const OVERLAY_MENU_ITEMS = [
	{ key: 'contact', name: 'Kontakt', type: 'link', testId: 'contact-item' },
	{ key: 'help', name: 'Hilfe', type: 'download', testId: 'help-item' },
	{
		key: 'impressum',
		name: 'Impressum',
		type: 'link',
		testId: 'impressum-item'
	},
	{ key: 'logout', name: 'Abmelden', type: 'button', testId: 'logout-item' }
]

const Menu = () => {
	const { user, isAuthenticated, loadingAuth, loadingVerify, logout } =
		useUser()

	function handleMenuClick(e) {
		if (e.key === 'logout') {
			logout()
		}
	}

	const menu = (
		<AntdMenu
			onClick={handleMenuClick}
			items={OVERLAY_MENU_ITEMS.map(item => ({
				label: (
					<div data-testid={item.testId}>
						{item.type === 'download' && isAuthenticated && (
							<a
								download="Hilfsdatei"
								href={`${user.helpFile}`}
								target="_blank"
								rel="noopener noreferrer"
							>
								<AkaTypography variant="bodySegoe2">{item.name}</AkaTypography>
							</a>
						)}
						{item.type === 'link' && (
							<a
								href="https://www.haevg-rz.de/impressum/"
								target="_blank"
								rel="noopener noreferrer"
							>
								<AkaTypography variant="bodySegoe2">{item.name}</AkaTypography>
							</a>
						)}
						{item.type === 'button' && (
							<AkaTypography variant="bodySegoe2">{item.name}</AkaTypography>
						)}
					</div>
				),
				key: item.key
			}))}
		/>
	)

	return (
		<MenuWrapper data-testid="main-menu" style={styles.wrapper}>
			{isAuthenticated && !loadingAuth && !loadingVerify && (
				<>
					<AkaTypography
						style={styles.user}
						variant="bodySegoe2"
						data-testid="user-data"
						color="white"
					>
						<>
							<img
								style={styles.icon}
								src={userIcon}
								height={18}
								alt="user-icon"
							/>
							{`${user.firstName.replace(/^\w/, c =>
								c.toUpperCase()
							)} ${user.lastName.replace(/^\w/, c => c.toUpperCase())}`}
						</>
					</AkaTypography>
					<Link to={ROUTES.account.base} style={styles.settingsLink}>
						<SettingOutlined style={styles.settings} />
					</Link>
				</>
			)}
			<Dropdown overlay={menu} placement="bottomLeft" trigger={['click']}>
				<MenuButton type="primary" size="default" data-testid="burger-button">
					<MenuOutlined style={styles.menu} />
				</MenuButton>
			</Dropdown>
		</MenuWrapper>
	)
}

export default withRouter(Menu)
