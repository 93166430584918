import gql from 'graphql-tag'
import { fragmentUser } from './fragments'

export const ME = gql`
	query me {
		me {
			...User
		}
	}
	${fragmentUser}
`
export const RELEASES = gql`
	query releases {
		releases {
			id
			createdAt
			name
			status
		}
	}
`

export const revisionHistoryChapterFragment = gql`
	fragment revisionHistoryChapters on ChapterType {
		id
		title
		pos
		fullTitle
		description
		chapters {
			id
		}
	}
`

export const RELEASE_REVISION_HISTORY_CHAPTERS = gql`
	${revisionHistoryChapterFragment}
	query revisionRelease($id: ID!) {
		release(id: $id) {
			id
			revisionHistoryRootChapter {
				chapters {
					...revisionHistoryChapters
				}
			}
		}
	}
`

export const RELEASE = gql`
	query release($id: ID!) {
		release(id: $id) {
			id
			name
			year
			quarter
			version
			isAttachmentsDirExist
			rootChapter {
				id
				title
				pos
				fullTitle
				parent {
					id
					pos
				}
				chapters {
					id
					title
					pos
					fullTitle
					chapters {
						id
						title
						pos
						fullTitle
						chapters {
							id
							title
							pos
							fullTitle
							chapters {
								id
								title
								pos
								fullTitle
								chapters {
									id
									title
									pos
									fullTitle
								}
							}
						}
					}
				}
			}
		}
	}
`

export const REQUIREMENTS_DIFF = gql`
	query requirementsDiff(
		$chapterId: ID!
		$comparedReleaseId: ID!
		$selectedContracts: [String]
	) {
		requirementsDiff(
			chapterId: $chapterId
			comparedReleaseId: $comparedReleaseId
			selectedContracts: $selectedContracts
		) {
			id
			comparedReleaseId
			prefixedCaliberId
			priority
			priorityText
			catalog
			title
			description
			note
			diff
			regionsDiff {
				diff
				comparedReleaseId
				comparedRequirementId
				okv
				name
				contractsDiff
			}
		}
	}
`

export const CHAPTER_DIFF = gql`
	query chapterDiff($id: ID!, $comparedReleaseId: ID!) {
		chapterDiff(id: $id, comparedReleaseId: $comparedReleaseId) {
			parent {
				id
				title
				pos
				fullTitle
			}
			chapters {
				id
				pos
				title
				fullTitle
			}
			id
			pos
			title
			fullTitle
			description
			comparedReleaseId
		}
	}
`

export const CHAPTER = gql`
	query chapter($id: ID!, $selectedContracts: [String]) {
		chapter(id: $id, selectedContracts: $selectedContracts) {
			parent {
				id
				title
				pos
				fullTitle
			}
			chapters {
				id
				pos
				title
				fullTitle
			}
			id
			pos
			title
			fullTitle
			description
			requirements {
				id
				prefixedCaliberId
				priority
				catalog
				title
				description
				note
				regions {
					okv
					name
					contracts
				}
			}
		}
	}
`

export const NOTIFICATIONS = gql`
	query notifications {
		notifications {
			id
			title
			message
			createdAt
			objectId
			contentObjectModel
			category
			status
			contentObject {
				... on ReleaseType {
					id
					name
					createdAt
					version
					year
					quarter
					releaseDate
					catalog
				}
				... on ApprovalRunType {
					id
					title
					submissionDeadline
					year
					approvalQuarter: quarter
					tenant {
						id
						name
						logo {
							url
						}
					}
				}
			}
		}
	}
`

export const SOFTWARE_SYSTEM_REPORT_LIST = gql`
	query softwareSystemReportList($systemId: ID!, $quarter: Int!, $year: Int!) {
		softwareSystemReportList(
			systemId: $systemId
			quarter: $quarter
			year: $year
		) {
			id
			status
			approvalRun {
				id
				submissionDeadline
				year
				quarter
				tenant {
					id
					name
					logo {
						url
					}
				}
			}
		}
	}
`

export const SOFTWARE_SYSTEM_REPORT_REQUIREMENTS = gql`
	query selectSoftwareSystemReportRequirements($id: ID!) {
		softwareSystemReport(id: $id) {
			id
			status
			isPrefilled
			changes {
				changedRequirementIds
				newRequirementIds
			}
			system {
				id
				systemName
			}
			selectedContractRegionGroups {
				id
				contract {
					id
					contractId
				}
				regions {
					okv
					name
				}
			}
			approvalRun {
				id
				quarter
				year
				tenant {
					id
					name
				}
				release {
					id
					rootChapter {
						id
						fullTitle
						chapters {
							id
							title
							pos
							fullTitle
							matrixRequirements(reportId: $id) {
								id
								caliberId
								title
								prefixedCaliberId
								contractRegionGroupIds
								selectedContractRegionGroupIds
								priority
							}
							chapters {
								id
								title
								pos
								fullTitle
								matrixRequirements(reportId: $id) {
									id
									caliberId
									title
									prefixedCaliberId
									contractRegionGroupIds
									selectedContractRegionGroupIds
									priority
								}
								chapters {
									id
									title
									pos
									fullTitle
									matrixRequirements(reportId: $id) {
										id
										caliberId
										title
										prefixedCaliberId
										contractRegionGroupIds
										selectedContractRegionGroupIds
										priority
									}
									chapters {
										id
										title
										pos
										fullTitle
										matrixRequirements(reportId: $id) {
											id
											caliberId
											title
											prefixedCaliberId
											contractRegionGroupIds
											selectedContractRegionGroupIds
										}
										chapters {
											id
											title
											pos
											fullTitle
											matrixRequirements(reportId: $id) {
												id
												caliberId
												title
												prefixedCaliberId
												contractRegionGroupIds
												selectedContractRegionGroupIds
												priority
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`

export const SOFTWARE_SYSTEM_REPORT_JUSTIFICATIONS = gql`
	query softwareSystemReportJustifications($id: ID!) {
		softwareSystemReport(id: $id) {
			id
			status
			system {
				id
				systemName
			}
			approvalRun {
				id
				quarter
				year
				tenant {
					id
					name
				}
			}
			justifications {
				id
				requirements {
					id
					prefixedCaliberId
					title
				}
				text
			}
		}
	}
`

export const SOFTWARE_SYSTEM_REPORT_SYSTEM_INFO = gql`
	query softwareSystemReportSystemInfo($id: ID!) {
		softwareSystemReport(id: $id) {
			id
			status
			systemVersion
			rolloutDate
			system {
				id
				manufacturerName
				systemName
				streetAndNumber
				postalCode
				city
				website
				drugModule {
					name
					date
					version
				}
				orderAddress
				mainContactPerson {
					id
					title
					firstname
					lastname
					phoneNumber
					email
					isMainContact
				}
				additionalContactPerson {
					id
					title
					firstname
					lastname
					phoneNumber
					email
					isMainContact
				}
			}
			approvalRun {
				id
				quarter
				year
				tenant {
					id
					name
				}
			}
		}
	}
`
export const SOFTWARE_SYSTEM_REPORT_SYSTEM_COSTS = gql`
	query sofwareSystemReportSystemCosts($id: ID!) {
		softwareSystemReport(id: $id) {
			id
			status
			systemVersion
			rolloutDate
			system {
				id
				systemName
				systemCosts
			}
			approvalRun {
				id
				quarter
				year
				tenant {
					id
					name
				}
			}
		}
	}
`

export const SOFTWARE_SYSTEM_REPORT_UNSATISFIED_REQUIREMENTS = gql`
	query softwareSystemReportUnsatiesfiedRequirements($id: ID!) {
		softwareSystemReport(id: $id) {
			id
			unsatisfiedRequirements {
				id
				title
				prefixedCaliberId
			}
		}
	}
`

export const CONTENT_CHECK_LIST = gql`
	query contentCheckList($systemId: ID!, $quarter: Int!, $year: Int!) {
		contentCheckList(systemId: $systemId, quarter: $quarter, year: $year) {
			id
			title
			approvalRun {
				submissionDeadline
				tenant {
					id
					name
					logo {
						url
					}
				}
			}
			proof(systemId: $systemId) {
				status
			}
			contract {
				contractId
			}
		}
	}
`

export const CONTENT_CHECK = gql`
	query contentCheck($id: ID!, $systemId: ID!) {
		contentCheck(id: $id, systemId: $systemId) {
			id
			title
			instructionFileUrl
			contract {
				id
				contractId
				name
			}
			approvalRun {
				quarter
				year
				tenant {
					id
					name
				}
			}
			proof(systemId: $systemId) {
				files {
					url
					name
				}
				status
				system {
					id
					systemName
				}
			}
		}
	}
`

export const CONTRACT_REGION_GROUPS = gql`
	query contractRegionGroups($id: ID!) {
		contractRegionGroupList(reportId: $id) {
			id
			contract {
				id
				contractId
			}
			regions {
				okv
				shortName
				name
			}
		}
		softwareSystemReport(id: $id) {
			id
			status
			isPrefilled
			selectedContractRegionGroups {
				id
			}
			system {
				id
				systemName
			}
			approvalRun {
				id
				quarter
				year
				tenant {
					id
					name
				}
			}
		}
	}
`

export const COMPARE_RELEASES_LIST = gql`
	query releasesToCompare($currentVersionId: ID!) {
		releasesToCompare(currentVersionId: $currentVersionId) {
			id
			name
			year
			quarter
			version
		}
	}
`

export const attachmentNodeFragment = gql`
	fragment attachmentNodeFragment on AttachmentContractReleaseNode {
		id
		diff
		changes
		attachmentSpuId
		attachmentObjectSpuId
		attachment {
			id
			title
			spuId
			printTypes
			type
		}
		attachmentObject {
			id
			title
			type
			file
			text
			link
			releaseType
			fileVersion
			page
		}
	}
`

export const ATTACHMENTS = gql`
	${attachmentNodeFragment}
	query releaseAttachments(
		$releaseId: ID!
		$comparedReleaseId: ID
		$query: String
		$before: String
		$after: String
		$first: Int
		$last: Int
		$attachment_Type: String
		$attachmentObject_Type: String
		$contract_ContractId: String
	) {
		releaseAttachments(
			releaseId: $releaseId
			comparedReleaseId: $comparedReleaseId
			query: $query
			before: $before
			after: $after
			first: $first
			last: $last
			attachment_Type: $attachment_Type
			attachmentObject_Type: $attachmentObject_Type
			contract_ContractId: $contract_ContractId
		) {
			pageInfo {
				hasNextPage
				endCursor
			}
			edges {
				node {
					...attachmentNodeFragment
				}
			}
		}
	}
`

export const ATTACHMENT_TYPES = gql`
	query attachmentsTypes($releaseId: ID!) {
		attachmentsTypes(releaseId: $releaseId)
	}
`

export const ATTACHMENT_OBJECT_TYPES = gql`
	query attachmentObjectTypes($releaseId: ID!) {
		attachmentObjectTypes(releaseId: $releaseId)
	}
`
export const ATTACHMENT_CONTRACTS = gql`
	query attachmentContracts($releaseId: ID!) {
		attachmentContracts(releaseId: $releaseId)
	}
`
export const RELEASE_CONTRACTS = gql`
	query releaseContracts($releaseId: ID!) {
		releaseContracts(releaseId: $releaseId) {
			contractId
			identification
		}
	}
`

export const requirementDiffFragment = gql`
	fragment requirementFragment on RequirementType {
		id
		prefixedCaliberId
		title
		priorityText
		description
		note
		diff
		chapter {
			pathFromRoot
		}
		regionsDiff {
			diff
			comparedReleaseId
			comparedRequirementId
			okv
			name
			contractsDiff
		}
	}
`
export const USER_CONTRACTS = gql`
	query userContracts {
		userContracts {
			key: id
			contractId
			identification
			typ
		}
	}
`
export const RELEASE_REQUIREMENTS_CHANGES = gql`
	${requirementDiffFragment}
	query releaseRequirementsChanges(
		$currentReleaseId: ID!
		$comparedReleaseId: ID!
		$selectedContracts: [String]
	) {
		releaseRequirementsChanges(
			currentReleaseId: $currentReleaseId
			comparedReleaseId: $comparedReleaseId
			selectedContracts: $selectedContracts
		) {
			currentReleaseId
			comparedReleaseId
			newRequirements {
				...requirementFragment
			}
			changedRequirements {
				...requirementFragment
			}
			deletedRequirements {
				...requirementFragment
			}
			changedAssignmentRequirements {
				id
				prefixedCaliberId
				title
				newRegionContractAssignments
				deletedRegionContractAssignments
			}
		}
	}
`

export const attachmentNodeChangesFragment = gql`
	fragment attachmentContractReleaseFragment on AttachmentContractReleaseNode {
		id
		changes
		diff
		contract {
			contractId
		}
		attachment {
			spuId
			title
			type
		}
		attachmentObject {
			title
			file
			type
		}
	}
`

export const RELEASE_ATTACHMENT_CHANGES = gql`
	${attachmentNodeChangesFragment}
	query attachmentReleaseChanges(
		$currentReleaseId: ID!
		$comparedReleaseId: ID!
		$selectedContracts: [String]
	) {
		releaseAttachmentChanges(
			currentReleaseId: $currentReleaseId
			comparedReleaseId: $comparedReleaseId
			selectedContracts: $selectedContracts
		) {
			comparedReleaseId
			currentReleaseId
			changedAttachments {
				...attachmentContractReleaseFragment
			}
			deletedAttachments {
				...attachmentContractReleaseFragment
			}
			newAttachments {
				...attachmentContractReleaseFragment
			}
		}
	}
`

export const SEARCH_RELEASE_REQUIREMENTS = gql`
	query searchReleaseRequirements(
		$releaseId: ID!
		$query: String!
		$selectedContracts: [String]
	) {
		releaseRequirementsSearch(
			releaseId: $releaseId
			query: $query
			selectedContracts: $selectedContracts
		) {
			id
			prefixedCaliberId
			title
			priority
			chapter {
				id
			}
		}
	}
`

export const RELEASE_ATTACHMENT_OBJECT_CONTRACTS = gql`
	query releaseAttachmentObjectContracts(
		$releaseId: ID!
		$attachmentSpuID: Int!
		$attachmentObjectSpuID: Int!
	) {
		releaseAttachmentObjectContracts(
			releaseId: $releaseId
			attachmentSpuID: $attachmentSpuID
			attachmentObjectSpuID: $attachmentObjectSpuID
		)
	}
`

export const ATTACHMENT_OBJECT_FILE_PATH = gql`
	query attachmentsObjectFilePath($attachmentObjectId: ID!) {
		attachmentsObjectFilePath(attachmentObjectId: $attachmentObjectId)
	}
`

export const SELECTIVE_CONTRACT_DEFINITION_CHANGES = gql`
	query releaseContractChanges(
		$currentReleaseId: ID!
		$comparedReleaseId: ID!
		$selectedContracts: [String]
	) {
		releaseContractChanges(
			currentReleaseId: $currentReleaseId
			comparedReleaseId: $comparedReleaseId
			selectedContracts: $selectedContracts
		) {
			currentReleaseId
			comparedReleaseId
			releaseContractsDiff {
				id
				version
				diff
				oldVersion
				contract {
					contractId
					identification
				}
			}
		}
	}
`

export const SOFTWARE_SYSTEMS = gql`
	query softwareSystems {
		softwareSystems {
			id
			systemName
			slogan
			website
			manufacturerName
			isParticipating
		}
	}
`

export const SOFTWARE_SYSTEM_APPROVALS = gql`
	query softwareSystemApprovals($systemId: ID!, $year: Int!, $quarter: Int!) {
		softwareSystem(id: $systemId) {
			id
			approvals(year: $year, quarter: $quarter) {
				status
				tenant {
					id
					name
					hexColor
				}
			}
		}
	}
`

export const SOFTWARE_SYSTEM_DETAILS = gql`
	query softwareSystem($id: ID!) {
		softwareSystem(id: $id) {
			id
			systemName
		}
	}
`

export const APPROVAL_FEED_LIST = gql`
	query approvalFeed($systemId: ID!, $quarter: Int!, $year: Int!) {
		approvalFeed(systemId: $systemId, quarter: $quarter, year: $year) {
			id
			createdAt
			createdByText
			isUserMessage
			actionMessage
			userMessage
			approvalRun {
				id
				tenant {
					id
					catalogName
					hexColor
				}
			}
		}
	}
`
