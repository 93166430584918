import gql from 'graphql-tag'
import { fragmentUser } from './fragments'

export const LOGIN = gql`
	${fragmentUser}
	mutation tokenAuth($email: String!, $password: String!) {
		tokenAuth(email: $email, password: $password) {
			token
			user {
				...User
			}
		}
	}
`
export const TOKEN_VERIFY = gql`
	${fragmentUser}
	mutation verifyToken($token: String!) {
		verifyToken(token: $token) {
			payload
			user {
				...User
			}
		}
	}
`

export const CHANGE_PASSWORD = gql`
	mutation changePassword(
		$currentPassword: String!
		$newPassword: String!
		$confirmPassword: String!
	) {
		changePassword(
			currentPassword: $currentPassword
			newPassword: $newPassword
			confirmPassword: $confirmPassword
		)
	}
`
export const SET_USER_CONTRACT_FILTER = gql`
	${fragmentUser}
	mutation setUserContractsFilter($contractList: [ID!]!) {
		setUserContractsFilter(input: { contractList: $contractList }) {
			user {
				...User
			}
		}
	}
`
export const SEND_RELEASE_PACKET_EMAIL = gql`
	mutation sendReleasePacketEmail(
		$releaseId: ID!
		$comparedReleaseId: ID
		$includeSvdPdfFiles: Boolean
		$includeSvdXmlFiles: Boolean
		$includeInspectionDocumentXmlFiles: Boolean
		$includeInspectionDocumentCsvFiles: Boolean
		$includeAkaPdf: Boolean
		$includeBaseFile: Boolean
		$includeHelpFiles: Boolean
		$includePcxFiles: Boolean
		$includeRevisionHistoryFile: Boolean
		$attachmentOption: Int
		$selectedContracts: [String]
		$isFiltered: Boolean
	) {
		sendReleasePacketEmail(
			input: {
				releaseId: $releaseId
				comparedReleaseId: $comparedReleaseId
				includeSvdPdfFiles: $includeSvdPdfFiles
				includeSvdXmlFiles: $includeSvdXmlFiles
				includeInspectionDocumentXmlFiles: $includeInspectionDocumentXmlFiles
				includeInspectionDocumentCsvFiles: $includeInspectionDocumentCsvFiles
				includeAkaPdf: $includeAkaPdf
				includeBaseFile: $includeBaseFile
				includeHelpFiles: $includeHelpFiles
				includePcxFiles: $includePcxFiles
				includeRevisionHistoryFile: $includeRevisionHistoryFile
				attachmentOption: $attachmentOption
				selectedContracts: $selectedContracts
				isFiltered: $isFiltered
			}
		) {
			inProgress
		}
	}
`
export const SELECT_CONTRACT_REGION_GROUPS = gql`
	mutation selectContractRegionGroups(
		$reportId: ID!
		$selectedGroupIds: [ID]!
	) {
		selectContractRegionGroups(
			reportId: $reportId
			selectedGroupIds: $selectedGroupIds
		)
	}
`
export const SUBMIT_JUSTIFY_UNSATISFIED_REQUIREMENTS = gql`
	mutation submitJustifyUnsatisfiedRequirements(
		$items: [JustificationInputType!]!
		$reportId: ID!
	) {
		justifyUnsatisfiedRequirements(items: $items, reportId: $reportId) {
			justificationsCount
		}
	}
`

export const SELECT_CONTRACT_REGION_GROUP_REQUIREMENTS = gql`
	mutation selectContractRegionGroupRequirements(
		$reportId: ID!
		$selection: [ContractRegionGroupsPerRequirement!]!
	) {
		selectContractRegionGroupRequirements(
			reportId: $reportId
			selection: $selection
		) {
			unchangedSelections
			deletedSelections
			createdSelections
		}
	}
`

export const UPDATE_SOFTWARE_SYSTEM = gql`
	mutation updateSoftwareSystem(
		$systemId: ID!
		$slogan: String!
		$website: String!
	) {
		updateSoftwareSystem(
			systemId: $systemId
			slogan: $slogan
			website: $website
		) {
			softwareSystem {
				id
				slogan
				website
				manufacturerName
			}
		}
	}
`
export const UPDATE_REPORT_SOFTWARE_SYSTEM_INFO = gql`
	mutation updateReportSoftwareSystemInfo(
		$reportInput: ReportInputType!
		$reportSystemInput: ReportSystemInputType!
		$mainContactInput: ContactPersonInputType!
		$additionalContactInput: ContactPersonInputType
	) {
		updateReportSoftwareSystemInfo(
			reportSystemInput: $reportSystemInput
			mainContactInput: $mainContactInput
			reportInput: $reportInput
			additionalContactInput: $additionalContactInput
		) {
			success
		}
	}
`
export const UPDATE_SOFTWARE_SYSTEM_COSTS = gql`
	mutation updateSoftwareSystemCosts(
		$systemId: ID!
		$systemCosts: [SystemCostType!]!
	) {
		updateSoftwareSystemCosts(systemId: $systemId, systemCosts: $systemCosts) {
			success
		}
	}
`

export const UPLOAD_CONTENT_CHECK_PROOF = gql`
	mutation contentCheckProofUpload(
		$systemId: ID!
		$contentCheckId: ID!
		$proofFiles: [Upload!]!
	) {
		contentCheckProofUpload(
			systemId: $systemId
			contentCheckId: $contentCheckId
			proofFiles: $proofFiles
		) {
			success
		}
	}
`

export const SEND_USER_MESSAGE_FOR_APPROVAL = gql`
	mutation sendUserMessageForApproval(
		$contentCheckId: ID
		$systemReportId: ID
		$softwareSystemId: ID!
		$userMessage: String!
	) {
		sendUserMessageForApproval(
			contentCheckId: $contentCheckId
			systemReportId: $systemReportId
			softwareSystemId: $softwareSystemId
			userMessage: $userMessage
		) {
			success
		}
	}
`
