export default {
	contractsFilterDescription:
		'Mit dieser Liste können Sie den Vertragsfilter im AKA-Portal automatisch vorbefüllen',
	activeContactsFilter: 'Meine Verträge als Filter anwenden',
	profileInfo: {
		email: 'E-Mail-Adresse',
		firstname: 'Vorname',
		lastname: 'Nachname'
	},
	profile: 'Profil',
	changePassword: 'Passwort ändern',
	forcePasswordChangeExplanation:
		'Ändern Sie ihr Passwort um fortfahren zu können.',
	changePasswordForm: {
		currentPassword: 'Aktuelles Passwort',
		newPassword: 'Neues Passwort',
		confirmPassword: 'Passwort wiederholen',
		save: 'Speichern',
		messages: {
			success: 'Ihr Passwort wurde erfolgreich geändert',
			error: 'Ihr Passwort konnte nicht geändert werden - {{message}}'
		},
		validation: {
			passwordMinLength:
				'Das Passwort muss mindestens {{minLength}} Zeichen lang sein.',
			passwordLowerCase:
				'Das Passwort muss mindestens einen Kleinbuchstaben enthalten.',
			passwordUpperCase:
				'Das Passwort muss mindestens einen Großbuchstaben enthalten.',
			passwordNumber: 'Das Passwort muss mindestens eine Zahl enthalten.',
			passwordSpecialCharacters:
				'Das Passwort muss mindestens ein Sonderzeichen enthalten: {{specialCharacters}}',
			passwordRequirements:
				'Das Passwort muss mindestens 10 Zeichen lang sein und mindestens einen Großbuchstaben, einen Kleinbuchstaben, eine Zahl und ein Sonderzeichen enthalten.',
			notMatchingPasswords: 'Die neuen Passwörter stimmen nicht überein.',
			confirmNewPassword: 'Bestätigung des neuen Passworts wird benötigt.'
		}
	},
	backToProfile: 'Zurück zum Profil',
	myContracts: 'Meine Verträge',
	systems: 'Meine Systeme',
	systemList: {
		editForm: {
			validation: {
				maxLength: 'Es sind maximal 255 Zeichen erlaubt',
				website: 'Bitte eine gültige URL mit http:// oder https:// angeben'
			},
			placeholders: {
				website: 'Website',
				slogan: 'Slogan'
			},
			edit: 'Bearbeiten',
			save: 'Speichern'
		},
		labels: {
			website: 'WEBSITE'
		},
		noResults: 'Keine Systeme zugewiesen'
	}
}
