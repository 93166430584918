import React, { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Layout, Menu } from 'antd'
import { useHistory } from 'react-router-dom'
import PageLayout from 'modules/layout/containers/PageLayout'
import { ROUTES } from 'modules/navigation'
import { AkaTypography } from '../../../components'
import { ChangePassword } from '../components/ChangePassword'
import { LocationDescriptor } from 'history'
import { useAntdMessageApi } from '../../../providers'

const { Content } = Layout

interface LocationState {
	forcePasswordChange?: boolean
}

const ChangePasswordPage = () => {
	const history = useHistory<LocationState>()
	const [t] = useTranslation()
	const messageApi = useAntdMessageApi()

	const navigations = [
		{ name: t('navigation.mainHeaderMenu.notifications'), key: ROUTES.home }
	]

	const navigationChangeHandler = (e: {
		key: LocationDescriptor<LocationState>
	}) => {
		if (e.key !== ROUTES.account.base) {
			history.push(e.key)
		}
	}

	const menuItems = [
		{
			key: 'account',
			label: t('account.backToProfile'),
			onClick: () => history.push(ROUTES.account.base)
		}
	]

	const sideBarContent = (
		<Menu
			mode="inline"
			style={{
				borderRight: 0,
				paddingTop: 24,
				fontFamily: 'SegoeUI, Sans',
				fontWeight: 'bold'
			}}
			items={menuItems}
		/>
	)

	const mainContent = (
		<Content
			style={{
				padding: '24px 24px 24px 48px',
				flexDirection: 'column',
				display: 'flex',
				maxWidth: 400,
				alignItems: 'flext-start'
			}}
		>
			<AkaTypography variant="h1">{t('account.changePassword')}</AkaTypography>
			<p>
				<Trans
					i18nKey={'account.changePasswordForm.validation.passwordRequirements'}
				/>
			</p>
			<ChangePassword />
		</Content>
	)

	useEffect(() => {
		if (history.location?.state?.forcePasswordChange) {
			messageApi?.open({
				type: 'warning',
				content: t('account.forcePasswordChangeExplanation')
			})
		}
	}, [])
	return (
		<PageLayout
			navigationItems={navigations}
			onNavigationChange={navigationChangeHandler}
			renderMainContent={mainContent}
			renderSidebarContent={sideBarContent}
		/>
	)
}

export default ChangePasswordPage
