import React, { useCallback, useEffect, useState } from 'react'
import { useMutation, useLazyQuery } from '@apollo/react-hooks'
import { useLocation, withRouter } from 'react-router-dom'
import { JWT } from 'configs/JWT'
import UserContext, { defaultUserData } from './userContext'
import { LOGIN, TOKEN_VERIFY } from 'modules/core/mutations'
import { ME } from 'modules/core/queries'
import { ROUTES } from 'modules/navigation'
import PropTypes from 'prop-types'

const UserContextProvider = ({ children, history }) => {
	const { pathname } = useLocation()
	const [tokenAuth, tokenAuthOpts] = useMutation(LOGIN)
	const [tokenVerify, tokenVerifyOpts] = useMutation(TOKEN_VERIFY)
	const [values, setValues] = useState(defaultUserData)
	const [me] = useLazyQuery(ME, {
		onCompleted: data => {
			setValues(value => ({
				...value,
				user: data.me
			}))
		},
		fetchPolicy: 'network-only'
	})

	const getToken = () => JWT.get()

	const logout = useCallback(() => {
		JWT.forget()
		setValues({ ...defaultUserData, isAuthenticated: false })
		history.push(ROUTES.login)
	}, [history])

	useEffect(() => {
		const token = JWT.get()
		if (token) {
			verifyToken(token)
		}
		if (!token && !values.isAuthenticated) {
			logout()
		}
	}, []) // eslint-disable-line

	const verifyToken = token => {
		tokenVerify({ variables: { token } })
	}

	useEffect(() => {
		if (
			tokenVerifyOpts.error ||
			(tokenVerifyOpts.data && !tokenVerifyOpts.data.verifyToken)
		) {
			logout()
		} else if (
			tokenVerifyOpts.data &&
			tokenVerifyOpts.data.verifyToken &&
			tokenVerifyOpts.data.verifyToken.user
		) {
			setValues(value => ({
				...value,
				isAuthenticated: true,
				user: tokenVerifyOpts.data.verifyToken.user
			}))
		}
	}, [tokenVerifyOpts, history, logout])

	useEffect(() => {
		if (
			pathname !== ROUTES.account.changePassword &&
			values.user.forcePasswordChange
		) {
			redirect(ROUTES.account.changePassword, { forcePasswordChange: true })
		}
	}, [pathname, values.user.forcePasswordChange]) // eslint-disable-line

	useEffect(() => {
		if (tokenAuthOpts.error) {
			logout()
		} else if (tokenAuthOpts.data) {
			const user = tokenAuthOpts.data.tokenAuth.user
			const token = tokenAuthOpts.data.tokenAuth.token
			saveAndRedirect(user, token)
		}
	}, [tokenAuthOpts, history]) // eslint-disable-line

	const saveAndRedirect = (user, token) => {
		JWT.keep(token)
		setValues(value => ({
			...value,
			isAuthenticated: true,
			user: { ...user }
		}))
		redirect(ROUTES.home)
	}

	const redirect = (route, state = {}) => {
		history.push(route, state)
	}

	const login = data => {
		tokenAuth({ variables: data })
	}

	return (
		<UserContext.Provider
			value={{
				...values,
				login,
				logout,
				redirect,
				error: tokenAuthOpts.error || tokenVerifyOpts.error,
				loadingAuth: tokenAuthOpts.loading,
				loadingVerify: tokenVerifyOpts.loading,
				hasToken: !!getToken(),
				refetchMe: me
			}}
		>
			{children}
		</UserContext.Provider>
	)
}

UserContextProvider.propTypes = {
	children: PropTypes.array,
	history: PropTypes.object
}

export default withRouter(UserContextProvider)
