import React from 'react'
import PropTypes from 'prop-types'
import {
	AkaButton,
	AkaTypography,
	Card,
	CardSubSection,
	HeadSection
} from 'components'
import moment from 'moment'
import 'moment/locale/de'
import { useTranslation } from 'react-i18next'
import ReactHtmlParser from 'react-html-parser'
import {
	CATALOG_TYPES,
	CATEGORIES,
	NOTIFICATION_STATUS_TYPES,
	QUARTERS,
	VERSIONS
} from 'modules/core/constants'
import theme from 'components/themeProvider/theme'
import { ROUTES } from 'modules/navigation'
moment.locale('de')

const styles = {
	notificationMessage: { textAlign: 'justify' },
	buttonWrapper: {
		display: 'flex',
		justifyContent: 'flex-end',
		paddingBottom: 16
	},
	infoRow: {
		display: 'flex',
		justifyContent: 'start'
	},
	infoItem: {
		display: 'flex',
		flexDirection: 'column',
		minWidth: 100
	},
	topBorder: {
		borderTop: `1px solid ${theme.gray}`
	},
	title: {
		fontWeight: 'bold'
	}
}

const columnsRelease = [
	{
		title: 'KATALOG',
		dataIndex: 'catalog',
		key: 'catalog'
	},
	{
		title: 'JAHR',
		dataIndex: 'year',
		key: 'year'
	},
	{
		title: 'QUARTAL',
		dataIndex: 'quarter',
		key: 'quarter'
	},
	{
		title: 'VERSION',
		dataIndex: 'version',
		key: 'version'
	},
	{
		title: 'FREIGABE',
		dataIndex: 'releaseDate',
		key: 'releaseDate'
	}
]

const columnsApprovalRun = [
	{
		title: 'JAHR',
		dataIndex: 'year',
		key: 'year'
	},
	{
		title: 'QUARTAL',
		dataIndex: 'quarter',
		key: 'quarter'
	},
	{
		title: 'EINREICHUNGSFRIST',
		dataIndex: 'submissionDeadline',
		key: 'submissionDeadline'
	}
]

const displayNotificationInfo = (header, data) => {
	return (
		<div style={styles.infoRow} data-testid={'notification-release-info'}>
			{data.map((dataPoint, i) => {
				return (
					<div style={styles.infoItem} key={i}>
						<AkaTypography style={styles.title} variant="body2">
							{header[i].title}
						</AkaTypography>
						<AkaTypography variant="body2">{dataPoint}</AkaTypography>
					</div>
				)
			})}
		</div>
	)
}

const NotificationItem = ({ item }) => {
	const [t] = useTranslation()

	const previewTag =
		item.status === NOTIFICATION_STATUS_TYPES.PREVIEW
			? [
					{
						text: t(
							`notification.notificationStatusTypes.${NOTIFICATION_STATUS_TYPES.PREVIEW}`
						),
						backgroundColor: 'redBrighter'
					}
			  ]
			: []

	return (
		<Card style={styles.topBorder} testId={`notification-item-${item.id}`}>
			<CardSubSection>
				<HeadSection
					title={item.title}
					tags={[
						moment(item.createdAt).format('DD.MM.YYYY').toString(),
						CATEGORIES[item.category],
						...previewTag
					]}
					id={item.id}
					logo={
						item.contentObject?.__typename === 'ApprovalRunType' &&
						item.contentObject.tenant.logo && {
							url: item.contentObject.tenant.logo.url,
							alt: t('notification.notificationsItem.logoAlt', {
								tenant: item.contentObject.tenant.name
							})
						}
					}
				/>
			</CardSubSection>
			{item.message && (
				<CardSubSection>
					<AkaTypography
						style={styles.notificationMessage}
						data-testid={`notification-message-${item.id}`}
						variant="body2"
					>
						{ReactHtmlParser(item.message)}
					</AkaTypography>
				</CardSubSection>
			)}
			{item.contentObject && (
				<>
					{item.contentObject.__typename === 'ApprovalRunType' ? (
						<>
							<CardSubSection gray={true}>
								{displayNotificationInfo(columnsApprovalRun, [
									item.contentObject.year,
									item.contentObject.approvalQuarter,
									moment(item.contentObject.submissionDeadline)
										.format('DD.MM.YYYY')
										.toString()
								])}
							</CardSubSection>
							<CardSubSection gray={true}>
								<div style={styles.buttonWrapper}>
									<AkaButton
										key="submit"
										type="primary"
										data-testid={`link-to-approval-${item.id}`}
										href={`${ROUTES.approval.base}/${item.contentObject.year}-Q${item.contentObject.approvalQuarter}`}
									>
										{t('notification.showApproval')}
									</AkaButton>
								</div>
							</CardSubSection>
						</>
					) : (
						<>
							<CardSubSection gray={true}>
								{displayNotificationInfo(columnsRelease, [
									CATALOG_TYPES[item.contentObject.catalog],
									item.contentObject.year,
									QUARTERS[item.contentObject.quarter],
									VERSIONS[item.contentObject.version],
									moment(item.contentObject.releaseDate)
										.format('DD.MM.YYYY')
										.toString()
								])}
							</CardSubSection>
							<CardSubSection gray={true}>
								<div style={styles.buttonWrapper}>
									<AkaButton
										key="submit"
										type="primary"
										data-testid={`link-to-catalog-${item.id}`}
										href={`${ROUTES.catalog}/${item.contentObject.id}`}
									>
										{t('notification.showCatalogue')}
									</AkaButton>
								</div>
							</CardSubSection>
						</>
					)}
				</>
			)}
		</Card>
	)
}

NotificationItem.propTypes = {
	item: PropTypes.shape({
		contentObject: PropTypes.object,
		id: PropTypes.string,
		title: PropTypes.string,
		category: PropTypes.string,
		createdAt: PropTypes.string,
		message: PropTypes.string
	}).isRequired
}

export default NotificationItem
