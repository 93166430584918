import styled from 'styled-components'
import { Table } from 'components'
import { Collapse, Tag, Table as MainTable } from 'antd'
import { DIFF, DiffValue } from 'modules/core/constants'
import { Theme, ThemeColorName } from 'components/themeProvider/theme'

export const getItemBackgroundColor = (theme: Theme, diff: DiffValue) => {
	switch (diff) {
		case DIFF.CHANGED:
			return theme.changedItem
		case DIFF.DELETED:
			return theme.deletedItem
		case DIFF.NEW:
			return theme.newItem
		default:
			return theme.primary
	}
}

export const getItemTopBorderColor = (theme: Theme, diff: DiffValue) => {
	switch (diff) {
		case DIFF.CHANGED:
			return `3px solid ${theme.changedItem}`
		case DIFF.DELETED:
			return `3px solid ${theme.deletedItem}`
		case DIFF.NEW:
			return `3px solid ${theme.newItem}`
		default:
			return `3px solid ${theme.primary}`
	}
}

export const CustomTable = styled(Table)`
	td {
		padding: 8px 16px !important;

		span::before ins::before del::before {
			content: ' ';
			white-space: pre;
		}
	}
	.ant-table-row-cell-last {
		.ant-table-header-column {
			padding-left: 3px !important;
		}
	}
	.ant-table-body {
		overflow-x: hidden !important;
	}
	.ant-table-row-level-0:hover > td {
		background: unset !important;
	}
`

export const RegionWrapper = styled.div`
	${({ diff, theme }: { diff: DiffValue; theme: Theme }) =>
		diff === DIFF.DELETED &&
		`color: ${theme.delContent} !important;
	     text-decoration: line-through !important;`}
`

export const ContractsWrapper = styled.div`
	${({ diff, theme }: { diff: DiffValue; theme: Theme }) =>
		diff === DIFF.DELETED &&
		`color: ${theme.delContent} !important;
	     text-decoration: line-through !important;`}
`

export const ChapterWrapper = styled.div`
	del {
		color: ${({ theme }) => theme.delContent};
		background-color: unset;
		img {
			opacity: 0.5;
			filter: alpha(opacity=30);
			border: 1px ${({ theme }) => theme.delContent} solid;
		}
	}
	ins {
		color: ${({ theme }) => theme.insContent};
		background-color: unset;
		text-decoration: unset;
		img {
			border: 1px ${({ theme }) => theme.insContent} solid;
		}
	}

	.table-with-diffs table {
		outline: 3px solid ${({ theme }) => theme.changedItem};
		outline-offset: -3px;
	}

	.table-with-diffs table [title='diff'] {
		color: ${({ theme }) => theme.changedItem};
	}
`

type CustomTagProps = {
	$backgroundColor?: ThemeColorName
}

export const CustomTag = styled(Tag)<CustomTagProps>`
	background-color: ${({ $backgroundColor, theme }) =>
		theme[$backgroundColor as keyof typeof theme] || theme.gray};
	text-transform: uppercase;

	// apply border-color only when a backgroundColor is specified to override the default gray border.
	${({ $backgroundColor, theme }) =>
		$backgroundColor && `border-color: ${theme[$backgroundColor]};`}
`

type BookMarkProps = {
	diff: DiffValue
}

export const BookMark = styled.div<BookMarkProps>`
	display: block;
	float: right;
	height: 50px;
	background: ${({ theme, diff }) => getItemBackgroundColor(theme, diff)};
	text-align: center;
	padding: 10px 10px 0 10px;
	position: relative;
	margin: -2px 10px 0 0;
	border-radius: 2px;
	font-size: 20px;
	text-decoration: none;
	color: #fff;
	::before {
		content: '';
		border-right: 10px solid transparent;
		border-left: 10px solid transparent;
		border-bottom: 10px solid white;
		position: absolute;
		left: 0;
		bottom: 0;
	}
`
export const SpecialRequirementWrapper = styled.div`
	[data-testid^='requirement-item-'] blockquote {
		border: 1px solid #eee;
		border-radius: 0.6em;
		background: #f5f5f5;
		padding: 0.2em !important;
		font-style: italic;
		display: inline-block !important;
		vertical-align: top;
		font-size: 1.2em;
		font-weight: 300;
		color: black !important;
	}
	[data-testid^='requirement-item-'] blockquote p {
		margin: 0 !important;
	}
	[data-testid^='requirement-item-'] li blockquote {
		padding: 0.4em !important;
	}

	[data-testid^='requirement-item-'] div,
	[data-testid^='requirement-item-'] p,
	[data-testid^='requirement-item-'] table {
		word-wrap: break-word !important;
		word-break: normal !important;
		color: black !important;
	}
	[data-testid^='requirement-item-'] table {
		table-layout: auto !important;
	}

	[data-testid^='requirement-item-'] table,
	[data-testid^='requirement-item-'] tr,
	[data-testid^='requirement-item-'] td {
		height: auto !important;
		width: auto !important;
		border: 1px solid #ccc !important;
	}

	[data-testid^='requirement-item-'] td {
		padding: 0.2em;
	}

	[data-testid^='requirement-item-'] table thead td {
		background: #d9d9d9;
	}

	[data-testid^='requirement-item-'] img {
		width: auto !important;
		max-width: 100% !important;
		min-width: 35% !important;
		height: auto !important;
	}

	[data-testid^='requirement-item-'] ul li {
		list-style: disc !important;
	}
`

export const TableWrapper = styled.div`
	display: flex;
	flex-direction: column;
`

export const CustomCollapse = styled(Collapse)`
	.ant-collapse-item {
		border-bottom: none;
		padding-bottom: 16px;
	}
	.ant-collapse-header {
		padding: 0px 0px 0px 24px !important;
	}
	.anticon {
		left: 0px !important;
	}
`

export const AttachmentTable = styled(MainTable)`
	font-family: Arial, sans-serif;
	font-size: 16px;
	.ant-table-row-level-0:hover > td {
		background: unset !important;
	}
`

export const AttachmentChangesTable = styled(MainTable)`
	.ant-table-row-level-0:hover > td {
		background: unset !important;
	}
`
