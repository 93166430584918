export const ROUTES = {
	home: '/', //notfications
	login: '/login',
	account: {
		base: '/account',
		changePassword: '/account/changePassword'
	},
	catalog: '/catalog',
	approval: {
		base: '/zulassung',
		report: {
			base: '/selbstauskunft',
			systemInfo:
				'/zulassung/:quarter/selbstauskunft/:id/informationen-praxissystem',
			systemInfoSlug: '/informationen-praxissystem',
			systemCosts: '/zulassung/:quarter/selbstauskunft/:id/kosten-praxissystem',
			systemCostsSlug: '/kosten-praxissystem',
			selectContractRegionsSlug: '/auswahl-vertraege',
			selectContractRegions:
				'/zulassung/:quarter/selbstauskunft/:id/auswahl-vertraege',
			selectRequirementsSlug: '/auswahl-anforderungen',
			selectRequirements:
				'/zulassung/:quarter/selbstauskunft/:id/auswahl-anforderungen',
			justifications: '/zulassung/:quarter/selbstauskunft/:id/begruendungen',
			justificationsSlug: '/begruendungen'
		},
		contentCheck: {
			base: '/ergebnisprüfung',
			contentCheckDetails:
				'/zulassung/:quarter/ergebnisprüfung/:id/system/:systemId'
		}
	},
	notFound: '/404'
}

export const ROUTE_FUNCTIONS = {
	getApprovalPath: (quarter: string) => `${ROUTES.approval.base}/${quarter}`,
	getSystemInfoPath: (quarter: string, reportId: string) =>
		`${ROUTES.approval.base}/${quarter}${ROUTES.approval.report.base}/${reportId}${ROUTES.approval.report.systemInfoSlug}`,
	getSystemCostsPath: (quarter: string, reportId: string) =>
		`${ROUTES.approval.base}/${quarter}${ROUTES.approval.report.base}/${reportId}${ROUTES.approval.report.systemCostsSlug}`,
	getSelectContractRegionsPath: (quarter: string, reportId: string) =>
		`${ROUTES.approval.base}/${quarter}${ROUTES.approval.report.base}/${reportId}${ROUTES.approval.report.selectContractRegionsSlug}`,
	getSelectRequirementsPath: (quarter: string, reportId: string) =>
		`${ROUTES.approval.base}/${quarter}${ROUTES.approval.report.base}/${reportId}${ROUTES.approval.report.selectRequirementsSlug}`,
	getJustificationsPath: (quarter: string, reportId: string) =>
		`${ROUTES.approval.base}/${quarter}${ROUTES.approval.report.base}/${reportId}${ROUTES.approval.report.justificationsSlug}`,
	getContentCheckDetailsPath: (
		quarter: string,
		contentCheckId: string,
		systemId: string
	) =>
		`${ROUTES.approval.base}/${quarter}${ROUTES.approval.contentCheck.base}/${contentCheckId}/system/${systemId}`
}
